<app-base-dialog [title]="title">
    <form [formGroup]="form" class="flex flex-col gap-4" (ngSubmit)="search()">
        <span class="font-bold text-deepsea-3">{{findYourText}}</span>
        <app-form-inputs
            *ngFor="let input of [nameInput, locationInput]"
            [input]="$any(input)"
            [formGroup]="form"
        ></app-form-inputs>

        <app-button (onClick)="hiddenButton.click()">
            <fa-icon class="mr-2" [icon]="['far', 'magnifying-glass']"></fa-icon>
            Zoeken
        </app-button>

        <button hidden #hiddenButton></button>
    </form>

    <mat-divider *ngIf="parties || loading" class="-mx-6 my-6"></mat-divider>

    <ng-container *ngIf="loading">
        <mat-spinner class="mx-auto mb-2" diameter="30"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="parties">
        <div *ngIf="parties.length === 0 && !loading" class="flex flex-col gap-6 mb-2">
            <span class="font-bold text-deepsea-3">Geen resultaten</span>
            <span>Er zijn geen zorgverleners gevonden met de opgegeven zoekcriteria.</span>
        </div>

        <div *ngIf="parties.length > 0" class="results flex flex-col gap-6">
            <span class="font-bold text-deepsea-3 mb-2">{{parties.length}} resultaten</span>
            <div
                *ngFor="let party of parties"
                class="p-4 lg:p-6 border rounded-3xl border-grey-2 flex flex-col lg:flex-row lg:items-center"
                [class.border-deepsea-2]="selectedHealthcareProvider === party"
            >
                <div class="flex flex-col">
                    <div class="font-bold">{{party.letterAttention}}</div>
                    <div>{{party.orgName}}</div>
                    <div>{{party.address.streetName}} {{party.address.houseNr}}</div>
                    <div>{{party.address.postalCode}} {{party.address.city}}</div>
                </div>

                <app-button
                    (onClick)="selectedHealthcareProvider = party"
                    [color]="selectedHealthcareProvider === party ? 'primary' : 'secondary'"
                    class="mt-2 lg:ml-auto lg:mt-0"
                >
                    {{ selectedHealthcareProvider === party ? 'Geselecteerd' : 'Selecteer' }}
                    <fa-icon *ngIf="selectedHealthcareProvider === party" class="ml-2" [icon]="['far', 'check']"></fa-icon>
                </app-button>
            </div>
        </div>
    </ng-container>

    <div class="flex flex-1 justify-between" dialog-actions>
        <app-button (onClick)="dialogRef.close()" color="transparent">
            Annuleren
        </app-button>
        <app-button (onClick)="save()">
            <fa-icon class="mr-2" [icon]="['far', 'save']"></fa-icon>
            Opslaan
        </app-button>

<!--        <app-form-dev-tools [form]="form"></app-form-dev-tools>-->
    </div>
</app-base-dialog>
