<div class="calendar px-8" [ngClass]="{'pt-7': locationService.activeLocation.onlineAvailable}">
    <div *ngIf="locationService.activeLocation.onlineAvailable" class="calendar__header flex justify-between items-center">
        <fa-icon
            class="icon"
            (click)="constructWeek('previous')"
            [icon]="['far', 'chevron-left']"
            [class.icon--disabled]="!canGoBack"
        ></fa-icon>
        <span>{{currentMonth}}</span>
        <fa-icon
            class="icon"
            (click)="constructWeek('next')"
            [icon]="['far', 'chevron-right']"
        ></fa-icon>
    </div>
    <div *ngIf="locationService.activeLocation.onlineAvailable" class="week my-4 flex justify-between">
        <div
            *ngFor="let day of currentWeek"
            (click)="selectDate(day)"
            class="flex flex-col calendar__day"
            [class.calendar__day--active]="day.selected"
            [class.calendar__day--disabled]="!day.isOpen"
            [matTooltip]="!day.isOpen ? 'Geen beschikbaarheid' : ''"
        >
            <span class="mb-4 flex justify-center">{{day.date | amLocal | amDateFormat: 'ddd'}}</span>
            <span class="date">{{day.date | amLocal | amDateFormat: 'DD'}}</span>
        </div>
    </div>

    <ng-container *ngIf="selectedDate">
        <mat-divider *ngIf="locationService.activeLocation.onlineAvailable"></mat-divider>
        <div class="timeslots">
            <ng-container *ngIf="timeSlots?.length === 0 || !timeSlots else slots">
                <div class="no-slots flex flex-col items-center justify-center py-16">
                    <ng-container *ngIf="locationService.activeLocation.onlineAvailable">
                        <span>Volledig volgeboekt</span>

                        <ng-container *ngIf="nextAvailability else callLocation">
                            <p class="text-center">Volgende beschikbare datum op {{this.nextAvailability | amLocal | amDateFormat: 'dddd, D MMMM' }}.</p>

                            <app-button (onClick)="goToDate()" class="mt-8">
                                Ga naar {{this.nextAvailability | amLocal | amDateFormat: 'dddd, D MMMM'}}
                                <fa-icon class="ml-2" [icon]="['far', 'arrow-right']"></fa-icon>
                            </app-button>
                        </ng-container>

                        <ng-template #callLocation>
                            <p class="text-center">
                                Bel naar
                                <a class="underline text-deepsea-1" [href]="'tel:' + this.locationService.activeLocation.phoneNumber">
                                    {{ this.locationService.activeLocation.phoneNumber }}
                                </a>
                                om een afspraak te maken.
                            </p>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngIf="!locationService.activeLocation.onlineAvailable">
                        <span>Locatie gebruikt geen online agenda</span>

                        <p class="text-center">
                            Bel naar
                            <a class="underline text-deepsea-1" [href]="'tel:' + this.locationService.activeLocation.phoneNumber">
                                {{ this.locationService.activeLocation.phoneNumber }}
                            </a>
                             om een afspraak te maken.
                        </p>
                    </ng-container>
                </div>
            </ng-container>

            <ng-template #slots>
                <div
                    *ngFor="let slot of timeSlots"
                    class="slot px-8 py-3"
                    [class.slot--active]="selectedTime === slot"
                    (click)="selectTime(slot)"
                >
                    <div class="slot__inner flex justify-between items-center">
                        <span>{{slot}}</span>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>
</div>
