import { Component } from '@angular/core';
import {BaseLoginStepComponent} from "../base-login-step/base-login-step.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormInput, FormInputType} from "@util/types/models";
import {LoginService} from "@core/services/login.service";
import { firstValueFrom } from 'rxjs';
import {HotToastService} from "@ngneat/hot-toast";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-email-login-step',
  templateUrl: './email-login-step.component.html',
  styleUrls: ['./email-login-step.component.scss']
})
export class EmailLoginStepComponent extends BaseLoginStepComponent {
  protected readonly FormInputType = FormInputType;
  public loginInput: FormInput = {
    label: 'E-mailadres',
    type: FormInputType.Text,
    key: 'email',
  };
  public loginForm: FormGroup;

  constructor(
      private formBuilder: FormBuilder,
      override loginService: LoginService,
      private hotToast: HotToastService,
  ) {
    super(loginService);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async validate(): Promise<void> {
    if (this.loginForm.valid) {
      try {
        const data = await firstValueFrom(this.loginService.getMailToken(this.loginForm.get('email').value));
        if(environment.appEnv === 'local' || environment.appEnv === 'development') {
          if(data && data.hasOwnProperty('debug')) {
            window.location.href = `${environment.appUrl}${data.debug}`;
          }
        }
        this.displayNextStep();
      } catch(e) {
        this.hotToast.error('Er is iets misgegaan. Probeer het later opnieuw.');
      }
    }
  }
}
