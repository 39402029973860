<app-base-page>
    <ng-container *ngIf="paymentWait()">
        <mat-spinner class="mx-auto" [diameter]="50"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="getStatus(['paid']) || !onlineAvailable">
        <ng-container header>
            <div class="flex items-center justify-center mb-8">
                <img src="assets/Check.svg" class="mr-4"/>
                <h1 class="text-3xl font-bold text-deepsea-3">
                    Afspraak {{ onlineAvailable ? 'bevestigd' : 'bevestigen' }}
                </h1>
            </div>
        </ng-container>

        <div class="flex gap-8 flex-wrap lg:flex-nowrap">
            <app-step-card class="w-full lg:w-1/2">
                <ng-container header>
                    <div class="card__title">
                        {{ cardTitle }}
                    </div>
                </ng-container>

                <ng-container body>
                    <ng-container *ngIf="!onlineAvailable">
                        <p>Je ontvangt een e-mail met jouw ingevulde gegevens.</p>
                        <p>Wij bellen je binnen 2 werkdagen op onderstaand nummer: <br /> <strong>{{clientInformation.phoneNumber}}</strong></p>
                        <p>Heb je een gemiste oproep, dan kun je ons terugbellen op: <br /> <strong>{{location.phoneNumber}}</strong></p>
                    </ng-container>
                    <ng-container *ngIf="onlineAvailable">
                        <p>Je ontvangt een e-mail met een bevestiging van de afspraak, inclusief een link om jouw gegevens verder aan te vullen.</p>
                        <p>Kijk alsjeblieft even in de spam-map als de e-mail niet binnenkomt in je inbox. Kun je de e-mail ook daar niet vinden, maak je dan geen zorgen. Je kunt ook deze pagina opslaan of printen via de opties onderaan deze pagina.</p>
                        <p>Daarnaast ontvang je ook een sms met de datum en tijd van je afspraak.</p>
                    </ng-container>

                    <mat-divider class="mt-2"></mat-divider>
                </ng-container>

                <ng-container footer>
                    <div class="flex gap-4 mt-6 no-print">
                        <app-button (click)="handleDownload()" color="secondary">
                            <fa-icon class="mr-2" [icon]="['far', 'download']"></fa-icon>
                            Opslaan
                        </app-button>
                        <app-button (click)="printPage()" color="secondary">
                            <fa-icon class="mr-2" [icon]="['far', 'print']"></fa-icon>
                            Printen
                        </app-button>
                        <app-button (onClick)="goToWebsite()">
                            <fa-icon class="mr-2" [icon]="['far', 'home']"></fa-icon>
                            Naar homepage
                        </app-button>
                    </div>
                </ng-container>
            </app-step-card>

            <app-appointment-summary
                class="w-full lg:w-1/2"
            ></app-appointment-summary>
        </div>
    </ng-container>
</app-base-page>
