<div class="flex gap-8 flex-wrap lg:flex-nowrap" *ngIf="locationService.activeLocation">
    <app-step-card class="w-full lg:w-1/2">
        <ng-container header>
            <app-selected-location-card-header></app-selected-location-card-header>
        </ng-container>

        <ng-container body>
            <ng-container
                *ngIf="locationService.activeLocation.onlineAvailable"
                [ngTemplateOutlet]="form"
                [ngTemplateOutletContext]="{ formGroup: infoFormGroup, inputs: infoInputs }"
            ></ng-container>

            <ng-container *ngIf="!locationService.activeLocation.onlineAvailable">
                <app-treatment-item
                        [treatment]="stepService.selectedExamType"
                        [selectedTreatment]="stepService.selectedExamType"
                ></app-treatment-item>

                <div class="p-6 border rounded-xl border-grey-2 flex flex-col text-deepsea-3">
                    <span class="mb-5">
                        Deze locatie heeft op dit moment <strong>geen online agenda</strong> beschikbaar. Je kunt telefonisch contact opnemen met de optiekzaak om een afspraak in te plannen.
                    </span>
                    <div class="flex gap-3 mb-3">
                        <fa-icon [icon]="['far', 'phone']"></fa-icon>
                        <a [href]="'tel:' + locationService.activeLocation.phoneNumber" class="text-deepsea-1 hover:underline">
                            {{locationService.activeLocation.phoneNumber}}
                        </a>
                    </div>
                    <div class="flex gap-3 mb-3">
                        <fa-icon [icon]="['far', 'clock']"></fa-icon>
                        <div class="flex gap-1 flex-wrap lg:flex-nowrap">
                            <span
                                class="font-bold"
                                [ngClass]="{'text-green': currentDay.currentlyOpen, 'text-error': !currentDay.currentlyOpen}"
                            >{{currentDay.currentlyOpen ? 'Geopend' : 'Gesloten'}}</span>
                            <span>·</span>

                            <div class="flex w-full justify-between gap-1">
                                <div class="flex">
                                    <span *ngIf="!showOpeningHours && currentDay.currentlyOpen">Sluit om {{currentDay.closesAt}}</span>
                                    <span class="max-w-40" *ngIf="!showOpeningHours && !currentDay.currentlyOpen">Opent op {{nextDay.parsedDay.toLowerCase()}} om {{nextDay.opensAt}}</span>
                                    <div class="flex flex-col gap-2 me-6" *ngIf="showOpeningHours">
                                    <span
                                            *ngFor="let day of openingHours"
                                            class="capitalize"
                                            [ngClass]="{'font-bold': day.current}"
                                    >
                                        {{day.parsedDay}}
                                    </span>
                                    </div>
                                </div>
                                <span *ngIf="!showOpeningHours">·</span>
                                <div class="flex">
                                    <span class="cursor-pointer" *ngIf="!showOpeningHours" (click)="showOpeningHours = !showOpeningHours">Meer tijden</span>
                                    <div class="flex flex-col gap-2" *ngIf="showOpeningHours">
                                    <span
                                            *ngFor="let day of openingHours"
                                            [ngClass]="{'font-bold': day.current}"
                                    >{{day.hours}}</span>
                                    </div>
                                </div>
                                <fa-icon
                                        (click)="showOpeningHours = !showOpeningHours"
                                        class="text-grey-3 ml-1 cursor-pointer" [icon]="['far', showOpeningHours ? 'chevron-up' : 'chevron-down']"
                                ></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-lightblue-1 p-6 rounded-xl flex gap-3 text-deepsea-3">
                    <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
                    <p>Wil je liever worden teruggebeld? Vul <strong>onderstaand formulier</strong> in. De optiekzaak neemt zo snel mogelijk contact met je op.</p>
                </div>

                <div class="flex flex-col gap-6">
                    <h4>Terugbelformulier</h4>
                    <ng-container
                            [ngTemplateOutlet]="form"
                            [ngTemplateOutletContext]="{ formGroup: infoFormGroup, inputs: callbackInputs }"
                    ></ng-container>
                </div>
            </ng-container>

            <mat-divider class="mt-2"></mat-divider>
        </ng-container>

        <ng-container footer>
            <app-step-card-footer
                [canProceed]="locationService.activeLocation.onlineAvailable ? (infoFormGroup.valid && infoFormGroup.get('dateOfBirth').value) : infoFormGroup.valid"
                (nextStep)="hiddenButton.nativeElement.click()"
            ></app-step-card-footer>

            <app-form-dev-tools
                [form]="infoFormGroup"
                [autoFillValues]="infoObj"
            ></app-form-dev-tools>
        </ng-container>
    </app-step-card>

    <app-appointment-summary
            class="w-full lg:w-1/2"
    ></app-appointment-summary>
</div>

<ng-template #form let-formGroup="formGroup" let-inputs="inputs">
    <form class="flex flex-col gap-4" [formGroup]="formGroup" (ngSubmit)="submit()">
        <ng-container *ngFor="let input of inputs">
            <ng-container *ngIf="isArray(input) else singleInput">
                <div class="grid grid-cols-1 gap-6" [ngClass]="getColumnLength(input.length)">
                    <app-form-inputs
                            *ngFor="let actualInput of input"
                            [input]="$any(actualInput)"
                            [formGroup]="formGroup"
                            [showLabel]="actualInput.type !== FormInputType.SingleCheckbox"
                    ></app-form-inputs>
                </div>
            </ng-container>

            <ng-template #singleInput>
                <app-form-inputs
                        [input]=" $any(input)"
                        [formGroup]="formGroup"
                        [showLabel]="$any(input).type !== FormInputType.SingleCheckbox"
                ></app-form-inputs>
            </ng-template>
        </ng-container>

        <button #hiddenButton hidden></button>
    </form>
</ng-template>
