import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: 'app-consent-banner',
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss']
})
export class ConsentBannerComponent implements OnInit {
  // State variables
  showSettings: boolean = false;
  consentAnalytics: boolean = false;
  consentMarketing: boolean = false;

  constructor(private gtmService: GoogleTagManagerService) {}

  // Wrapper for Google Tag Manager's dataLayer
  gtag(...args) {
    window['dataLayer'] = window['dataLayer'] || [];
    console.log('Pushing to dataLayer:', arguments);
    window['dataLayer'].push(arguments);
  }

  // Hide the banner with animation
  hideBanner() {
    const banner = document.getElementById('cookie-consent-banner');
    if (banner) {
      banner.classList.add('slide-down');
      setTimeout(() => {
        banner.style.display = 'none';
      }, 500); // Match the duration of the slide-down animation
    }
  }

  // Set consent based on user choices
  setConsent(consent: { necessary: boolean; analytics: boolean; marketing: boolean }) {
    const consentMode = {
      'functionality_storage': consent.necessary ? 'granted' : 'denied',
      'security_storage': consent.necessary ? 'granted' : 'denied',
      'ad_storage': consent.marketing ? 'granted' : 'denied',
      'analytics_storage': consent.analytics ? 'granted' : 'denied',
      'personalization_storage': consent.marketing ? 'granted' : 'denied',
    };

    try {
      this.gtag('consent', 'update', consentMode);
      this.gtag('event', 'consent_loaded_or_updated');
      localStorage.setItem('consentMode', JSON.stringify(consentMode));
    } catch (e) {
        console.error('Error while setting GTM consent mode', e);
    }
  }

  // Handler for "Accepteer alle" button
  acceptAll() {
    this.setConsent({
      necessary: true,
      analytics: true,
      marketing: true
    });
    this.hideBanner();
  }

  // Handler for "Accepteer selectie" button
  acceptSelection() {
    this.setConsent({
      necessary: true,
      analytics: this.consentAnalytics,
      marketing: this.consentMarketing
    });
    this.hideBanner();
  }

  // Handler for "Weigeren" button
  rejectAll() {
    this.setConsent({
      necessary: true, // Assuming 'necessary' cannot be rejected
      analytics: false,
      marketing: false
    });
    this.hideBanner();
  }

  // Show settings options
  showSettingsOptions() {
    this.showSettings = true;
  }

  ngOnInit() {
    if (localStorage.getItem('consentMode') === null) {
      const banner = document.getElementById('cookie-consent-banner');
      if (banner) {
        banner.style.display = 'flex';
      }
    }
  }
}
