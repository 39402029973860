import { ValidatorFn, AbstractControl } from '@angular/forms';
import moment from "moment";

export function MaxFutureDate(amount: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (!control.value || moment(control.value).isValid() === false) {
            return null;
        }

        const date = moment(control.value).startOf('day').toDate();
        const now = moment().add(amount, 'years').startOf('day').toDate();
        if (date > now) {
            return {maxFutureDate: {value: control.value, years: amount}};
        }
        return null;
    };
}

export function MaxPastDate(amount: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (!control.value || moment(control.value).isValid() === false) {
            return null;
        }

        const date = moment(control.value).startOf('day').toDate();
        const now = moment().subtract(amount, 'years').startOf('day').toDate();

        if (date < now) {
            return {maxPastDate: {value: control.value, years: amount}};
        }
        return null;
    };
}
