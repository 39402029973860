<app-base-page [centered]="true" title="Mijn profiel" class="profile">
    <mat-spinner *ngIf="!patient" class="mx-auto my-32" diameter="30"></mat-spinner>
    <div class="flex flex-col gap-6" *ngIf="patient">
        <app-section *ngFor="let section of sections" [title]="section.title">
            <div
                *ngFor="let inner of section.innerSections; let last = last"
                class="border rounded-xl border-grey-2 p-6 flex flex-col gap-6"
                [ngClass]="{'mb-6' : !last}"
            >
                <div class="flex justify-between">
                    <div *ngIf="inner.title.icon else labelOnly" class="border border-grey-2 rounded-full py-1 px-2">
                        <fa-icon class="text-deepsea-1 mr-2" [icon]="inner.title.icon"></fa-icon>
                        {{ inner.title.label }}
                    </div>
                    <ng-template #labelOnly>
                        <h4>{{ inner.title.label }}</h4>
                    </ng-template>

                    <div *ngIf="inner.action"
                         (click)="inner.action(inner)"
                         class="text-deepsea-1 hover:underline cursor-pointer"
                    >
                        <fa-icon class="mr-2" [icon]="['far', 'edit']"></fa-icon>
                        Bewerken
                    </div>
                </div>

                <ng-container *ngIf="section.title !== healthCareTeamTitle else healthCareSection">
                    <div *ngFor="let input of inner.inputs" class="text-sm flex flex-col">
                        <div *ngIf="input.label" class="font-bold mb-2">
                            {{input.label}}
                        </div>
                        <div *ngIf="inner.formGroupName && patient[inner.formGroupName][input.key] else singleKey">
                            {{ getValue(input, patient[inner.formGroupName][input.key]) }}
                        </div>
                        <ng-template #singleKey>
                            <div *ngIf="patient[input.key] else infoWarning">
                                {{ getValue(input, patient[input.key]) }}
                            </div>
                        </ng-template>
                        <ng-template #infoWarning>
                            <app-chip class="self-start" [color]="'red'">
                                <fa-icon class="me-2" [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                {{ 'Vul je ' + input.label + ' in' }}.
                            </app-chip>
                        </ng-template>
                    </div>
                </ng-container>

                <ng-template #healthCareSection>
                    <ng-container *ngIf="patient[inner.formGroupName]">
                        <div *ngFor="let input of inner.inputs" class="text-sm flex flex-col">
                            <div class="font-bold" *ngIf="inner.formGroupName && patient[inner.formGroupName][input.key] else singleKey">
                                {{ getValue(input, patient[inner.formGroupName][input.key]) }}
                            </div>
                            <ng-template #singleKey>
                                <div *ngIf="patient[input.key]">
                                    {{ getValue(input, patient[input.key]) }}
                                </div>
                            </ng-template>
                        </div>

                        <div class="text-sm flex flex-col -mt-5">
                            <div class="flex flex-col">
                                <span>{{patient[inner.formGroupName].orgName}}</span>
                                <span>{{patient[inner.formGroupName].address.streetName}} {{patient[inner.formGroupName].address.houseNr}}</span>
                                <span>{{patient[inner.formGroupName].address.postalCode}} {{patient[inner.formGroupName].address.city}}</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </div>
        </app-section>
    </div>
</app-base-page>
