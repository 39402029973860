import {Component, Input} from '@angular/core';
import {BasePageComponent} from "../../../base-page/base-page.component";
import {CompanyLocationService} from "@core/services/company-location.service";
import {firstValueFrom} from "rxjs";
import {StepService} from "@core/services/step.service";
import {BaseStepComponent} from "../base-step/base-step.component";

export interface StepComponent {
    title: string;
}

@Component({
  selector: 'app-select-treatment',
  templateUrl: './select-treatment.component.html',
  styleUrls: ['./select-treatment.component.scss']
})
export class SelectTreatmentComponent extends BaseStepComponent {
  public selectedTreatment = null;
  public availableTypes = [];

  constructor(
      public locationService: CompanyLocationService,
      override stepService: StepService,
  ) {
    super(stepService);


    if (this.stepService.selectedExamType) {
      this.selectedTreatment = this.stepService.selectedExamType;
    }

    this.availableTypes = this.locationService.examinationTypes?.filter(proc => proc.isBookableOnline);
  }

  selectTreatment(treatment) {
    this.selectedTreatment = treatment;
    this.stepService.selectedExamType = treatment;
  }
}
