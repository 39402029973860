import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentSummaryComponent } from './appointment-summary.component';
import {MomentModule} from "ngx-moment";
import {StepCardModule} from "@components/molecules/step-card/step-card.module";
import {MatDividerModule} from "@angular/material/divider";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
    declarations: [
        AppointmentSummaryComponent
    ],
    exports: [
        AppointmentSummaryComponent
    ],
    imports: [
        CommonModule,
        MomentModule,
        StepCardModule,
        MatDividerModule,
        FontAwesomeModule
    ]
})
export class AppointmentSummaryModule { }
