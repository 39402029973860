import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {
  faArrowLeftLong,
  faArrowRight, faArrowRightLong,
  faBolt, faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCircleCheck, faClock,
  faCoffee,
  faDownload,
  faEdit,
  faEllipsisV, faEnvelope,
  faExclamationTriangle,
  faHome, faInfoCircle, faLockKeyhole, faMagnifyingGlass, faPhone, faPlus, faPrint, faSave, faTimesCircle,
  faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {faCode} from "@fortawesome/pro-regular-svg-icons/faCode";
import {faTimes} from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  faBars,
  faCheckCircle,
  faExclamationCircle,
  faHouseMedical,
  faLock, faQuestionCircle,
  faUserDoctor
} from "@fortawesome/pro-solid-svg-icons";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class FontAwesomeLibraryModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCoffee,
      faHome,
      faCode,
      faTimes,
      faBolt,
      faEdit,
      faEllipsisV,
      faDownload,
      faTrash,
      faExclamationTriangle,
      faExclamationCircle,
      faPlus,
      faSave,
      faCheck,
      faArrowRight,
      faCheckCircle,
      faArrowLeftLong,
      faArrowRightLong,
      faChevronLeft,
      faChevronRight,
      faChevronDown,
      faChevronUp,
      faInfoCircle,
      faPrint,
      faLockKeyhole,
      faLock,
      faUserDoctor,
      faHouseMedical,
      faQuestionCircle,
      faTimesCircle,
      faMagnifyingGlass,
      faCircle,
      faCircleCheck,
      faBars,
      faPhone,
      faEnvelope,
      faClock
    );
  }
}
