<div class="dialog__wrapper flex">
  <div class="leftSide flex flex-col" *ngIf="hasLeftSide">
    <ng-content select="[dialog-left]"></ng-content>
  </div>

  <div class="flex flex-col relative dialog__column">
    <div mat-dialog-title>
      <div class="flex">
        <h4 class="mb-0 mr-3 self-center" *ngIf="title">{{title}}</h4>

        <ng-content select="[dialog-title]"></ng-content>

        <app-icon-button
            *ngIf="showCloseButton"
          class="ml-auto"
          color="secondary"
          (onClick)="hasCloseAction ? closeAction.emit() : dialogRef.close()"
        >
          <fa-icon class="flex" [icon]="['far', 'times']"></fa-icon>
        </app-icon-button>
      </div>
    </div>

    <mat-dialog-content
      (scroll)="onScroll($event)"
      class="flex flex-1 flex-col"
      [class.mat-dialog-content--noPadding]="noContentPadding"
      [class.mat-dialog-content--overflowVisible]="visibleOverflow"
    >
      <ng-content></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions>
      <ng-content select="[dialog-actions]"></ng-content>
    </mat-dialog-actions>
  </div>
</div>

