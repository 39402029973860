import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterInformationComponent } from './enter-information.component';
import {StepCardModule} from "@components/molecules/step-card/step-card.module";
import {
    SelectedLocationCardHeaderModule
} from "@components/atoms/selected-location-card-header/selected-location-card-header.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {FormDevToolsModule} from "@components/molecules/forms/form-dev-tools/form-dev-tools.module";
import {AppointmentSummaryModule} from "@components/organisms/appointment-summary/appointment-summary.module";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {StepCardFooterModule} from "@components/molecules/step-card-footer/step-card-footer.module";
import {MatListModule} from "@angular/material/list";
import {TreatmentItemModule} from "@components/atoms/treatment-item/treatment-item.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
  declarations: [
    EnterInformationComponent
  ],
    imports: [
        CommonModule,
        StepCardModule,
        SelectedLocationCardHeaderModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormInputsModule,
        FormDevToolsModule,
        AppointmentSummaryModule,
        FormErrorModule,
        StepCardFooterModule,
        MatListModule,
        TreatmentItemModule,
        FontAwesomeModule
    ]
})
export class EnterInformationModule { }
