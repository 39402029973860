import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BehaviorSubject, debounceTime, distinctUntilChanged, Observable} from "rxjs";
import {ChangeDetectorRef, Component, HostListener, OnInit} from "@angular/core";
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";

@UntilDestroy()
@Component({
  selector: 'app-form-zd-input',
  templateUrl: './form-zd-input.component.html',
  styleUrls: ['./form-zd-input.component.scss']
})
export class FormZdInputComponent extends BaseFormInputComponent implements OnInit {
  inputArray: number[] = [];
  inputType: string = 'text';
  inputValues: string[] = [];
  public zdNumbersMatch = false;
  public zdNumberIncomplete = null;

  constructor(override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  @HostListener('paste', ['$event']) async onPaste(event: ClipboardEvent) {
    const text = await navigator.clipboard.readText();
    if (text) {
      const textArray = text.split('');
      textArray.forEach((char, index) => {
        if (this.inputValues[index] === '') {
          this.inputValues[index] = char;
        }
      });

      this.formGroup.get(this.input.key).setValue(this.inputValues.join(''));
    }
  }

  ngOnInit(): void {
    this.inputArray = Array(this.input.length).fill(0);
    this.inputValues = Array(this.input.length).fill('');
    const zdValue = this.formGroup.get(this.input.key).value;
    if(zdValue) {
      this.writeValue(zdValue);
      // this.checkMatch();
    }

    this.formGroup.get(this.input.key).valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1500), untilDestroyed(this))
      .subscribe((value) => {
        this.zdNumberIncomplete = value.length !== this.input.length;
        this.zdNumberIncomplete ? this.formGroup.get(this.input.key).setErrors({zdNumberIncomplete: true}) : this.formGroup.get(this.input.key).setErrors(null);
    });
  }

  writeValue(zdValue): void {
    this.inputValues = zdValue.split('');
    if (this.input.key.includes('Blurred')) {
      this.inputType = 'password';
    }
  }

  handleKeydown(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      // this.onDelete(event, index);
      return;
    } else {
      this.onInput(event, index);
    }
  }

  onInput(event: any, index: number): void {
    const nextInput = event.target.value ? document.getElementById(`${this.input.key}${index + 1}`) : null;
    this.hideOrShow(index, nextInput, event.target.value);
  }

  // checkMatch(): void {
  //   const blurredValue = this.formGroup.get('zdNumberBlurred')?.value;
  //   const normalValue = this.formGroup.get(this.input.key)?.value;
  //   this.zdNumbersMatch = blurredValue === normalValue && normalValue.length === this.input.length;
  // }

  onDelete(event: any, index: number): void {
    setTimeout(() => {
      const prevInput = document.getElementById(`${this.input.key}${index - 1}`);
      this.hideOrShow(index, prevInput, event.target.value);
    }, 10)
  }

  private hideOrShow(index, input, targetValue) {
    this.inputValues[index] = targetValue;

    if (this.inputValues.every((value) => value !== '')) {
      if (this.input.key.includes('Blurred')) {
        this.inputType = 'password';
      }
    } else {
      this.inputType = 'text';
    }

    this.formGroup.get(this.input.key)?.setValue(this.inputValues.join(''));
    // this.checkMatch();

    if (input) {
      input.focus();
    }
  }
}
