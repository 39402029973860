<app-base-dialog
    [showCloseButton]="!!loading"
    title="SMS code verstuurd"
>
    <p class="mb-1">
        Om de afspraak te bevestigen heeft u een verificatiecode ontvangen op
        <span class="phoneNumber">{{data.phoneNumber}}</span>
    </p>

    <form [formGroup]="smsFormGroup" (ngSubmit)="save()">
        <app-form-inputs [input]="smsInput" [formGroup]="smsFormGroup"></app-form-inputs>
            <form-error
              *ngIf="codeInvalid"
              [texts]="['De ingevoerde verficatiecode is onjuist.']"
            >
            </form-error>

        <button #hiddenButton hidden></button>
    </form>

    <div class="ml-auto flex gap-4" dialog-actions>
        <app-button
            [disabled]="loading"
            color="secondary"
            (click)="dialogRef.close()"
        >Annuleren</app-button>
        <app-button
            [disabled]="loading"
            color="primary"
            (click)="hiddenButton.click()"
        >Bevestigen</app-button>
    </div>
</app-base-dialog>
