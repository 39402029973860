import {OnInit} from '@angular/core';
import { Component } from '@angular/core';
import {IdleService} from "@core/services/idle.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Mijn Okulus - cliëntenportaal';

    constructor(private idleService: IdleService) {
    }

  ngOnInit() {
    this.idleService.setIdle();
    // this.router.events.subscribe(() => {
    //   this.idleService.setIdle();
    // });
  }
}
