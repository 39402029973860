import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentBannerComponent } from './consent-banner.component';
import {FormsModule} from "@angular/forms";



@NgModule({
    declarations: [
        ConsentBannerComponent
    ],
    exports: [
        ConsentBannerComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class ConsentBannerModule { }
