import {AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {StepService} from "@core/services/step.service";
import {CompanyLocationService} from "@core/services/company-location.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormInput, FormInputType, FormSelectInput} from "@util/types/interfaces";
import {debounceTime, distinctUntilChanged, firstValueFrom} from "rxjs";
import {AppointmentService} from "@core/services/appointment.service";
import {BaseStepComponent} from "../base-step/base-step.component";
import moment from "moment/moment";
import {parseInitials} from "@util/helpers/name-helper";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {SameEmailValidator} from "@util/validators/sameEmailValidator";
import {EmailValidator} from "@util/validators/validatorHelper";
import {StepCardFooterComponent} from "@components/molecules/step-card-footer/step-card-footer.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MobilePhoneValidator} from "@util/validators/mobilePhoneValidator";
import {HotToastService} from "@ngneat/hot-toast";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {Router} from "@angular/router";

@UntilDestroy()
@Component({
  selector: 'app-enter-information',
  templateUrl: './enter-information.component.html',
  styleUrls: ['./enter-information.component.scss']
})
export class EnterInformationComponent extends BaseStepComponent implements OnInit {
  @ViewChild('hiddenButton', { static: false }) hiddenButton: ElementRef<HTMLButtonElement>;
  @ViewChild('form', { static: false }) formTemplate: TemplateRef<any>;
  @ViewChild(StepCardFooterComponent) stepper: StepCardFooterComponent;

  public infoInputs: (FormInput | FormSelectInput | FormInput[])[] = [
    {label: 'Geslacht *', key: 'gender', type: FormInputType.SingleRadio, selectOptions: [{name: 'Man', value: 'male'}, {name: 'Vrouw', value: 'female'}, {name: 'Onbekend', value: 'unkown'}]},
    [
      {label: 'Initialen *', key: 'initials', type: FormInputType.Text},
      {label: 'Voornaam *', key: 'firstName', type: FormInputType.Text},
      {label: 'Achternaam *', key: 'lastName', type: FormInputType.Text},
    ],
    {label: 'E-mail *', key: 'email', type: FormInputType.Text},
    {label: 'Herhaal e-mail *', key: 'emailRepeat', type: FormInputType.Text},
    {label: 'Geboortedatum *', key: 'dateOfBirth', type: FormInputType.DateOfBirth},
    {label: 'Telefoonnummer *', key: 'phoneNumber', type: FormInputType.Text},
    [
      {label: 'Postcode *', key: 'postalCode', formGroupName: 'address', type: FormInputType.Text},
      {label: 'Huisnummer *', key: 'houseNr', formGroupName: 'address', type: FormInputType.Text},
      {label: 'Toevoeging', key: 'houseNrSuffix', formGroupName: 'address', type: FormInputType.Text},
    ],
    [
      {label: 'Straatnaam *', key: 'streetName', formGroupName: 'address', type: FormInputType.Text, disabled: true},
      {label: 'Plaats *', key: 'city', formGroupName: 'address', type: FormInputType.Text, disabled: true},
    ],
    {label: 'Opmerkingen', key: 'remarks', type: FormInputType.Textarea},
    {label: "Door dit vakje aan te vinken, stem je ermee in dat wij je nu en in de toekomst op de hoogte houden van belangrijke informatie over je behandeling, inclusief afspraken, voortgang en actuele informatie.", key: 'agree', type: FormInputType.SingleCheckbox},
  ];
  public callbackInputs: (FormInput | FormSelectInput | FormInput[])[] = [];
  public showOpeningHours = false;
  public openingHours = [];
  public currentDay = null;
  public nextDay = null;

  protected readonly FormInputType = FormInputType;
  constructor(
      override stepService: StepService,
      public locationService: CompanyLocationService,
      private formBuilder: FormBuilder,
      private appointmentService: AppointmentService,
      private toastService: HotToastService,
      private router: Router
  ) {
    super(stepService);
  }

  ngOnInit(): void {
    const usedKeys = ['gender', 'email', 'emailRepeat', 'phoneNumber', 'remarks', 'agree'];
    this.callbackInputs = this.infoInputs.filter(input => {
        if (this.isArray(input)) {
            return input.some(subInput => usedKeys.includes(subInput.key));
        }
        return usedKeys.includes(input.key);
    });
    // FIXME: Can't seem to fix filtering out the initials input, so this'll have to do for now.
    this.callbackInputs.splice(1, 0, [
      {label: 'Voornaam *', key: 'firstName', type: FormInputType.Text},
      {label: 'Achternaam *', key: 'lastName', type: FormInputType.Text}
    ]);

    // FIXME: Should build up controls dynamically.
    const isOnlineAvailable = this.locationService.activeLocation.onlineAvailable;
    let controls = {
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      email: ['', [EmailValidator, Validators.required]],
      emailRepeat: ['', [EmailValidator, SameEmailValidator]],
      phoneNumber: ['', [Validators.required, MobilePhoneValidator]],
      remarks: [''],
      agree: [false],
      locationId: [this.locationService.activeLocation.id],
    } as any;

    if (isOnlineAvailable) {
      controls = {
        ...controls,
        initials: ['', [Validators.required]],
        dateOfBirth: [null], // FIXME: Removed the [Validators.required], because it triggers validation immediately with the inputMask module. Added manual check in HTML.
        address: this.formBuilder.group({
          postalCode: ['', [Validators.required]],
          houseNr: ['', [Validators.required]],
          houseNrSuffix: [''],
          city: ['', [Validators.required]],
          streetName: ['', [Validators.required]],
        }),
      }
    }

    super.infoFormGroup = this.formBuilder.group(controls);

    this.infoFormGroup.get('address')?.get('city').disable();
    this.infoFormGroup.get('address')?.get('streetName').disable();

    if (this.appointmentService.clientInformation) {
      this.infoFormGroup.patchValue(this.appointmentService.clientInformation);
    }

    this.infoFormGroup.get('initials')?.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), untilDestroyed(this))
      .subscribe(value => {
        if (value) {
          const parsed = parseInitials(value);
          this.infoFormGroup.get('initials').patchValue(parsed);
        }
      });

    if (this.locationService.activeLocation.openingHours) {
      const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const currentDay = moment().locale('en').format('ddd').toLowerCase();

      this.openingHours = days.map((day, index) => {
        const dayObj = this.locationService.activeLocation.openingHours[day];
        return {
          day: day,
          parsedDay: moment().day(index).format('dddd'),
          hours: dayObj.closed ? 'Gesloten' : `${dayObj.start} - ${dayObj.end}`,
          current: day === currentDay,
          closed: dayObj.closed,
          currentlyOpen: day === currentDay && moment().isBetween(moment(dayObj.start, 'HH:mm'), moment(dayObj.end, 'HH:mm')),
          opensAt: moment(dayObj.start, 'HH:mm').format('HH:mm'),
          closesAt: moment(dayObj.end, 'HH:mm').format('HH:mm')
        }
      })
        .sort((a, b) => {
          const currentDayIndex = days.indexOf(currentDay);
          const aIndex = (days.indexOf(a.day) - currentDayIndex + days.length) % days.length;
          const bIndex = (days.indexOf(b.day) - currentDayIndex + days.length) % days.length;
          return aIndex - bIndex;
      });

      this.currentDay = this.openingHours.find(day => day.current);
      this.nextDay = this.openingHours.find(day => !day.closed && !day.current);
    }
  }

  async submit() {
    if(this.infoFormGroup.get('agree').value === true) {
      this.infoFormGroup.get('remarks').patchValue(this.infoFormGroup.get('remarks').value + ' - Cliënt is akkoord met marketingberichten.');
    }
    this.appointmentService.clientInformation = this.infoFormGroup.getRawValue();

    if (this.locationService.activeLocation.onlineAvailable) {
      await this.createPendingAppointment();
    } else {
      await this.createCallbackAppointment();
    }
  }

  async createCallbackAppointment(): Promise<void> {
    const body = {
      ...this.infoFormGroup.getRawValue(),
      to: this.locationService.activeLocation.email,
      chosenExamType: this.stepService.selectedExamType.prettyName
    }
    await firstValueFrom(this.appointmentService.sendCallbackEmail(body));
    await this.router.navigate(['/afspraak-maken/bedankt']);
  }

  async createPendingAppointment(): Promise<boolean> {
    const slicedHour = this.appointmentService.selectedTime.slice(0, -3);
    const slicedMinute = this.appointmentService.selectedTime.slice(-2);
    const startDate = moment(this.appointmentService.selectedDate).set({
      hour: slicedHour,
      minute: slicedMinute
    });

    const endDate = moment(this.appointmentService.selectedDate).set({
      hour: slicedHour,
      minute: slicedMinute
    }).add(this.stepService.selectedExamType.duration, 'minute');

    this.appointmentService.appointmentDTO = {
      calendarEntry: {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        type: 'appointment',
      } as any,
      companyLocationId: this.locationService.activeLocation.id,
      examinationType: this.stepService.selectedExamType.name,
    }

    try {
      const response = await firstValueFrom(this.appointmentService.create(this.appointmentService.appointmentDTO));
      if (response) {
        this.appointmentService.appointmentId = response.id;
        this.appointmentService.appointmentDTO.id = response.id;
        return true;
      }
    } catch(e) {
      console.error(e);
      openToast(this.toastService, 'Deze tijd is al bezet, kies een ander tijdslot.', ToastType.Error);
      this.stepper.displayPreviousStep();
      this.stepper.displayPreviousStep();
    }

    return false;
  }

  isArray(input: FormInput | FormSelectInput | FormInput[]): input is FormInput[] {
    return Array.isArray(input);
  }

  getColumnLength(length: number): string {
    return `lg:grid-cols-${length}`;
  }
}
