<div #errorElement>
  <div
    class="flex flex-col relative errorWrapper"
    [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
  >
  <mat-form-field class="formField w-full" formErrorContainer>
      <input
        matInput
        [placeholder]="input.placeholder || 'DD - MM - JJJJ'"
        [formControlName]="input.key"
        [inputMask]="dateInputMask"
      />

      <fa-icon
        *ngIf="isDisabled()"
        matSuffix
        [icon]="['far', 'lock-keyhole']"
        class="disabledIcon"
      ></fa-icon>
    </mat-form-field>
  </div>
</div>
