import {Component, ViewChild} from '@angular/core';
import {BaseStepComponent} from "../base-step/base-step.component";
import {StepService} from "@core/services/step.service";
import {PaymentService} from "@core/services/payment.service";
import {firstValueFrom} from "rxjs";
import {AppointmentService} from "@core/services/appointment.service";
import moment from "moment";
import {CompanyLocationService} from "@core/services/company-location.service";
import {AppointmentVerifyService} from "@core/services/appointment-verify.service";
import {MatDialog} from "@angular/material/dialog";
import {
  SmsConfirmationDialogComponent
} from "@components/organisms/dialogs/sms-confirmation-dialog/sms-confirmation-dialog.component";
import {StepCardFooterComponent} from "@components/molecules/step-card-footer/step-card-footer.component";
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {HotToastService} from "@ngneat/hot-toast";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends BaseStepComponent {
  public selectedPaymentMethod = null;
  public selectedIssuer = null;
  public proceedText = 'Betalen';
  public paymentMethods = null;

  @ViewChild(StepCardFooterComponent) stepper: StepCardFooterComponent;

  constructor(
      override stepService: StepService,
      private paymentService: PaymentService,
      private appointmentService: AppointmentService,
      private locationService: CompanyLocationService,
      private appointmentVerifyService: AppointmentVerifyService,
      private dialog: MatDialog,
      private router: Router,
      private hotToast: HotToastService,
      private gtmService: GoogleTagManagerService
  ) {
    super(stepService);

    this.paymentMethods = [];
    this.paymentMethods.push({id: 'payment_in_store', longDesc: 'Betalen in de winkel'});

    // TODO: remove this after online payments are to be enabled in production.
    if (environment.appEnv !== 'production') {
      this.paymentService.getMethods().subscribe(methods => {
        this.paymentMethods.push(...methods.map(method => {
          return {
            ...method,
            longDesc: `Betalen met ${method.description}`
          }
        }));
      });
    }
  }

  selectMethod(method) {
    this.selectedPaymentMethod = method;
    this.stepService.selectedPaymentMethod = method;

    if (method.id === 'payment_in_store') {
      this.proceedText = method.longDesc;
      return;
    }

    this.proceedText = 'Betalen met ' + method.description;
  }

  public async createPayment(): Promise<void> {
    const data = {
      method: this.selectedPaymentMethod.id,
      issuerId: this.selectedIssuer ? this.selectedIssuer.id : null,
    }

    const patient = {
      ...this.appointmentService.clientInformation
    }
    this.appointmentService.appointmentDTO.paymentType = this.selectedPaymentMethod === 'payment_in_store' ? 'pay-in-store' : 'mollie';
    this.appointmentService.appointmentDTO.patient = patient;

    const appointment = {
      ...this.appointmentService.appointmentDTO,
      id: this.appointmentService.appointmentId,
      location: this.locationService.activeLocation,
      examinationProtocol: this.stepService.selectedExamType
    };

    if (this.selectedPaymentMethod.id !== 'payment_in_store') {
      this.paymentService.createPayment(
          data,
          {
            ...appointment,
            examinationType: this.stepService.selectedExamType.name
          })
          .subscribe(response => {
            localStorage.setItem('paymentId', response.paymentId);
            localStorage.setItem('appointment', JSON.stringify(appointment));
            window.location.replace(response.redirectUrl);

            this.createGTMEvent();
          });
    } else {
      this.stepper.canProceed = false;
      this.appointmentVerifyService.requestSms(this.appointmentService.appointmentId, patient.phoneNumber).subscribe(_ => {
        this.verifySms(appointment);
      }, e => {
        console.error(e);
        openToast(this.hotToast, 'Er is iets misgegaan bij het versturen van de SMS. Klopt het ingevulde mobiele nummer?', ToastType.Error);
        this.stepper.canProceed = true;
      })
    }
  }

  createGTMEvent() {
    const gtmTag = {
      event: 'Lead - Afspraak gemaakt',
      afspraak_location: this.locationService.activeLocation.name,
      afspraak_type: this.stepService.selectedExamType.prettyName,
    };

    try {
      this.gtmService.pushTag(gtmTag).then(() => {});
    } catch (e) {
      console.error('Error while pushing GTM tag', e);
    }
  }

  verifySms(appointment) {
    const dialogRef = this.dialog.open(SmsConfirmationDialogComponent, {
      data: {
        phoneNumber: this.appointmentService.appointmentDTO.patient.phoneNumber,
        appointmentId: appointment.id
      }
    });

    dialogRef.afterClosed().subscribe(async smsCode => {
      if (smsCode) {
        localStorage.setItem('appointment', JSON.stringify(appointment));
        this.createGTMEvent();
        this.router.navigate(['/afspraak-maken/bedankt'],
        {
          queryParams: {
            method: this.selectedPaymentMethod.id,
            code: smsCode
          }
        });
      } else {
        this.stepper.canProceed = true;
      }
    });
  }
}
