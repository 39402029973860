import {Component, OnDestroy} from '@angular/core';
import {BaseStepComponent} from "../base-step/base-step.component";
import {StepService} from "@core/services/step.service";
import {AppointmentService} from "@core/services/appointment.service";
import {CompanyLocationService} from "@core/services/company-location.service";
import {PaymentService} from "@core/services/payment.service";
import {environment} from "../../../../../environments/environment";
import { createEvent } from 'ics';
import {parseAddress} from "@util/helpers/name-helper";
import moment from "moment";
import {GoogleTagManagerService} from "angular-google-tag-manager";
export enum PaymentStatus {
  open = "open",
  canceled = "canceled",
  pending = "pending",
  authorized = "authorized",
  expired = "expired",
  failed = "failed",
  paid = "paid"
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends BaseStepComponent implements OnDestroy {
  public paymentId: string = null;
  public payment = null;
  public messageToDisplay = 'Wachten op betaling...';
  public payInStore = false;
  public location = this.locationService.activeLocation;
  public onlineAvailable = this.location.onlineAvailable;
  public cardTitle = '';
  public clientInformation = this.appointmentService.clientInformation;

  constructor(
      override stepService: StepService,
      private appointmentService: AppointmentService,
      private locationService: CompanyLocationService,
      private paymentService: PaymentService,
      private gtmService: GoogleTagManagerService
  ) {
    super(stepService);
    this.cardTitle = this.onlineAvailable ? 'Wat nu?' : `Beste ${this.clientInformation.firstName}, wij bellen je terug`;

    // TODO: Set everything in appointmentService, instead of spreading it out over multiple services
    if (this.onlineAvailable) {
      const appointment = JSON.parse(localStorage.getItem('appointment'));
      this.appointmentService.appointmentDTO = appointment;
      this.appointmentService.clientInformation = appointment.patient;
      this.stepService.selectedExamType = appointment.examinationProtocol;
      this.locationService.activeLocation = appointment.location;
      this.paymentId = localStorage.getItem('paymentId');
    }

    if (this.paymentId) {
      this.paymentService.checkPayment(this.appointmentService.appointmentDTO.id).subscribe(response => {
        if (response) {
          this.payment = response;
          this.checkPayment();
        } else {
          const interval = setInterval(() => { // TODO why do we need this is we have websockets?
            this.paymentService.checkPayment(this.appointmentService.appointmentDTO.id).subscribe(response => {
              this.payment = response;
              this.checkPayment(interval);
            });
          }, 5000);
        }
      });
    } else if (!this.onlineAvailable) {
      console.log('Terugbelafspraak!')
    } else {
      this.payInStore = true;
      // this.router.navigate(['/boeking']);
    }

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  getStatus(statuses = []) {
    return statuses.indexOf(this.payment?.status) > -1 || this.payInStore;
  }

  paymentWait() {
    return [PaymentStatus.open, PaymentStatus.pending].indexOf(this.payment?.status) > -1;
  }

  // TODO: ngOnDestroy(), remove localStorage items
  ngOnDestroy() {
      localStorage.removeItem('paymentId');
      localStorage.removeItem('appointment');
  }

  private checkPayment(interval?): void {
    switch(this.payment.status) {
      case PaymentStatus.open:
        this.messageToDisplay = 'Wachten op betaling...';
        break;
      case PaymentStatus.paid:
        this.messageToDisplay = 'Bedankt voor je boeking bij Okulus!';
        if (interval) {
          clearInterval(interval);
        }

        this.createAppointment(interval);
        break;
      case PaymentStatus.authorized:
        this.messageToDisplay = 'Wachten op betaling...';
        break;
      case PaymentStatus.failed:
        this.messageToDisplay = 'De betaling is mislut.';
        break;
      case PaymentStatus.canceled:
        this.messageToDisplay = 'U heeft de betaling afgebroken.';
        break;
      case PaymentStatus.pending:
        this.messageToDisplay = 'Wachten op betaling...';
        break;
      case PaymentStatus.expired:
        this.messageToDisplay = 'De betaling is verlopen.';
        break;
    }
  }

  private createAppointment(interval): void {
    // FIXME: should be postcode API!
    this.appointmentService.appointmentDTO.patient.address = {
      ...this.appointmentService.appointmentDTO.patient.address,
      city: '',
      streetName: ''
    }
    this.appointmentService.complete(this.appointmentService.appointmentDTO.id, this.appointmentService.appointmentDTO).subscribe(data => {
      interval ? clearInterval(interval) : null;
    });
  }

    protected readonly environment = environment;

  printPage() {
    window.print();
  }

  goToWebsite() {
    window.open(this.environment.websiteUrl, '_blank');
  }

  async handleDownload() {
    const year = this.appointmentService.selectedDate.getFullYear();
    const month = this.appointmentService.selectedDate.getMonth();
    const day = this.appointmentService.selectedDate.getDate();
    const hour = +this.appointmentService.selectedTime.split(':')[0];
    const minutes = +this.appointmentService.selectedTime.split(':')[1];
    console.log([year, month, day, hour, minutes]);

    const event = {
      title: `${this.stepService.selectedExamType.prettyName} bij ${this.locationService.activeLocation.name}`,
      // description: '',
      start: [year, month, day, hour, minutes],
      duration: { minutes: this.stepService.selectedExamType.duration },
      location: parseAddress(this.locationService.activeLocation.address)
    }
    const filename = 'OkulusAfspraak.ics'
    const file = await new Promise((resolve, reject) => {
      createEvent(event as any, (error, value) => {
        if (error) {
          reject(error)
        }

        resolve(new File([value], filename, { type: 'text/calendar' }))
      })
    })
    const url = URL.createObjectURL(file as any);

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
  }
}
