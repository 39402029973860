<div id="cookie-consent-banner" class="cookie-consent-banner flex-col gap-4">
    <h3>Cookies</h3>
    <p>
        Wij gebruiken cookies om uw ervaring op onze site te verbeteren.<br />
        Door op accepteren te drukken stemt u in met ons
        <a href="https://cms.okulus.nl/wp-content/uploads/2025/01/Privacyverklaring_v2.pdf" class="text-blue-60">Privacybeleid</a> en ons gebruik van cookies.
        <a href="https://cms.okulus.nl/wp-content/uploads/2025/01/Privacyverklaring_v2.pdf" class="text-blue-60">Meer informatie.</a>
    </p>

    <!-- Checkboxes are visible only when showSettings is true -->
    <div *ngIf="showSettings" class="cookie-consent-options flex gap-2 items-center justify-center">
        <label>
            <input type="checkbox" checked disabled />Functionele
        </label>
        <label>
            <input [(ngModel)]="consentAnalytics" type="checkbox" />Analytische
        </label>
        <label>
            <input [(ngModel)]="consentMarketing" type="checkbox" />Tracking
        </label>
    </div>

    <!-- Buttons based on the current state -->
    <div class="flex gap-2 items-center justify-center flex-wrap">
        <!-- Initial Buttons -->
        <button *ngIf="!showSettings" (click)="acceptAll()" class="cookie-consent-button btn-success">
            Accepteren
        </button>
        <button *ngIf="!showSettings" (click)="showSettingsOptions()" class="cookie-consent-button btn-grayscale">
            Instellingen aanpassen
        </button>

        <!-- Settings Buttons -->
        <div *ngIf="showSettings" class="flex gap-2 items-center justify-center flex-wrap">
            <button (click)="acceptAll()" class="cookie-consent-button btn-success">
                Accepteer alle
            </button>
            <button (click)="acceptSelection()" class="cookie-consent-button btn-outline">
                Accepteer selectie
            </button>
            <button (click)="rejectAll()" class="cookie-consent-button btn-grayscale">
                Weigeren
            </button>
        </div>
    </div>
</div>
