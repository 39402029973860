import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAppointmentComponent } from './create-appointment.component';
import {BasePageModule} from "../base-page/base-page.module";
import {SelectTreatmentModule} from "./steps/select-treatment/select-treatment.module";
import {SelectDateTimeModule} from "./steps/select-date-time/select-date-time.module";
import {EnterInformationModule} from "./steps/enter-information/enter-information.module";
import {PaymentModule} from "./steps/payment/payment.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ConfirmationModule} from "./steps/confirmation/confirmation.module";
import {environment} from "../../../environments/environment";
import {ConsentBannerModule} from "@components/organisms/consent-banner/consent-banner.module";



@NgModule({
  declarations: [
    CreateAppointmentComponent
  ],
    imports: [
        CommonModule,
        BasePageModule,
        SelectTreatmentModule,
        SelectDateTimeModule,
        EnterInformationModule,
        PaymentModule,
        MatProgressSpinnerModule,
        ConfirmationModule,
        ConsentBannerModule
    ],
    providers: [
        {provide: 'googleTagManagerId',  useValue: environment.googleTagManagerId}
    ]
})
export class CreateAppointmentModule { }
