import {
  Component, ComponentRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, merge} from "rxjs";
import {FormInput, FormInputType, FormSelectInput} from "@util/types/interfaces";
import {FormInputDirective, inputMap} from "@util/directives/form-input/form-input.directive";
import {FormTextInputComponent} from "@components/molecules/forms/types/form-text-input/form-text-input.component";
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";
import {
  FormDropdownInputComponent
} from "@components/molecules/forms/types/form-dropdown-input/form-dropdown-input.component";
import {
  FormDatepickerInputComponent
} from "@components/molecules/forms/types/form-datepicker-input/form-datepicker-input.component";
import {FormRadioInputComponent} from "@components/molecules/forms/types/form-radio-input/form-radio-input.component";
import {
  FormTextareaInputComponent
} from "@components/molecules/forms/types/form-textarea-input/form-textarea-input.component";
import {
  FormSingleCheckboxInputComponent
} from "@components/molecules/forms/types/form-single-checkbox-input/form-single-checkbox-input.component";
import {getAddress} from "@util/helpers/getAddress";
import {PostalcodeService} from "@core/services/postalcode.service";


@Component({
  selector: 'app-form-inputs',
  templateUrl: './form-inputs.component.html',
  styleUrls: ['./form-inputs.component.scss']
})
export class FormInputsComponent implements OnInit, OnDestroy {
  @Input({required: true}) input: FormInput | FormSelectInput;
  @Input() searchForAddress = true; // Disabled postalcode lookup
  @Input() showLabel = true;
  // selectOptions: {key: string, value: string}[];
  @Input({required: true}) formGroup: FormGroup;
  @Output() addressFound: EventEmitter<any> = new EventEmitter<any>();
  // @Output() events: EventEmitter<FormEvent> = new EventEmitter<FormEvent>(); // TODO: add typings for events.
  public FormInputType = FormInputType;
  @ViewChild('labelElement') labelElement;
  public addressSub = null;
  public inputSub = null;

  @ViewChild(FormInputDirective, {static: true}) inputDirective: FormInputDirective;

  constructor(
    private postalcodeService: PostalcodeService,
    protected formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
/*
    this.formGroup.valueChanges.subscribe(value => {
      // console.log(value);
    });
*/

    // TODO: Use this for backend calls, etc.
    // if (this.input.valueChanges) {
    //   this.inputSub = this.formGroup.get(this.input.key).valueChanges
    //     .pipe(
    //       distinctUntilChanged(),
    //       debounceTime(100)
    //     )
    //     .subscribe((value) => {
    //     this.input.valueChanges(value, this.input, this.formGroup)
    //   });
    // }

    if (!this.searchForAddress) {
      return;
    }

    if (this.formGroup.get('address')) {
      const setSub = (keyToCheck: string) => {
        this.addressSub = merge(
            this.formGroup.get(keyToCheck).valueChanges
        )
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(async value => {
              this.addressFound.emit(
                  await getAddress(
                      this.formGroup,
                      this.postalcodeService,
                      'address',
                  ));
            });
      }

      // Separate the subscription to prevent multiple address lookups in a short time
      if (this.input.key === 'postalCode') {
        setSub('address.houseNr');
      }

      if (this.input.key === 'houseNr') {
        setSub('address.postalCode');
      }
    }

    this.spawnInput();
  }

  ngOnDestroy() {
    if (this.addressSub) {
      this.addressSub.unsubscribe();
    }

    if (this.inputSub) {
      this.inputSub.unsubscribe();
    }
  }

  public getFormGroup(key): any {
    return [(this.formGroup.get(key) as FormGroup).controls];
  }

  public getKeys(group: any): string[] {
    // console.log(group)
    return Object.keys(group).map((c, i) => c);
  }
  log(data) {
    console.log(data);
  }

  spawnInput() {
    const componentRef: ComponentRef<BaseFormInputComponent> = this.inputDirective.viewContainerRef.createComponent(inputMap[this.input.type]);
    componentRef.instance.input = this.input;
    componentRef.instance.formGroup = this.formGroup;
  }
  //
  // public getValueName(value): any {
  //   return this.input.selectOptions.find(o => o.value === value).name;
  // }
  //
  // onTypeRemoved(type, key) {
  //   const types = this.getArray(key).value as string[];
  //   this.removeFirst(types, type);
  //   this.getArray(key).patchValue(types);
  // }
  //
  // private removeFirst<T>(array: T[], toRemove: T): void {
  //   const index = array.indexOf(toRemove);
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //   }
  // }

}
