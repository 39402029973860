import {Component, Input} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent {
  @Input() title: string;
  @Input() centered = false;

  constructor(
      private router: Router
  ) {
  }
}
