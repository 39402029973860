import {Component, inject} from '@angular/core';
import {LoginService} from "@core/services/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {SecurityService} from "@core/services/security.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    private loginSteps = inject(LoginService).getLoginSteps();
    get currentStep() {
        return this.loginSteps[this.loginService.currentStepIndex];
    }

    constructor(private loginService: LoginService,
                private securityService: SecurityService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        if (this.securityService.accessToken) {
            this.router.navigate(['/dashboard']);
        }

        this.activatedRoute.queryParams.subscribe(async params => {
            if (!params['token'] || !params['id']) {
                // TODO: show error or redirect to error step.
                console.error('No token or id found in query params.');
                this.loginService.loginToken = null;
                return;
            }

            this.loginService.loginToken = params['token'];
            this.loginService.userId = params['id'];

            try {
                const valid = await firstValueFrom(this.loginService.validateMailToken(this.loginService.loginToken, this.loginService.userId));
                this.loginService.currentStepIndex = 2;
                if(!valid) {
                    throw new Error('Token validation failed');
                }
            } catch (e) {
                // TODO: go to error step maybe?
                console.error(e);
            }
        });
    }
}
