import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormInput} from "@util/types/interfaces";
import {FormInputType} from "@util/types/models";
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {UntilDestroy} from "@ngneat/until-destroy";

export interface ConfirmDialogData {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  showWarning?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public additionalMessageForm: FormGroup;
  public textInput: FormInput = {
    type: FormInputType.Textarea,
    key: 'message',
    label: 'Bericht',
  }
  @ViewChild('hiddenButton') hiddenButton: HTMLButtonElement;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private idle: Idle,
    @Inject(MAT_DIALOG_DATA) public data: {
        title: string,
        message: string,
        confirmButtonText: string,
        cancelButtonText?: string,
        showWarning?: boolean,
        sendAdditionalMessage?: boolean
        showCloseButton?: boolean,
        idle?: boolean,
    }
  ) {
      if (data.idle) {
          idle.setInterrupts([]);
          idle.onTimeoutWarning.subscribe((countdown) =>  {
              // console.log(`You will timeout in ${countdown} seconds`);
              const minutes = Math.floor(countdown / 60);
              const seconds = countdown % 60;

              if (minutes === 0) {
                  this.data.message = `Je sessie verloopt over ${seconds} seconden.`;
              } else {
                  this.data.message = `Je sessie verloopt over ${minutes} minuten.`;
              }
          });

          idle.onTimeout.subscribe(() => {
              dialogRef.close(false);
          });
      }
  }

  ngOnInit(): void {
    this.additionalMessageForm = this.formBuilder.group({
      message: ['', [Validators.required]]
    }, {updateOn: 'blur'});
  }
}
