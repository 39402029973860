import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {
    ConfirmationDialogComponent
} from "@components/organisms/dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SecurityService} from "@core/services/security.service";
import {Keepalive} from "@ng-idle/keepalive";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class IdleService {
    idleState = null;
    timedOut = false;
    lastPing?: Date = null;

    constructor(
        private dialog: MatDialog,
        private securityService: SecurityService,
        private idle: Idle,
        private keepalive: Keepalive,
        private router: Router,
    ) {
    }

    setIdle(): void {
        let dialogRef = null;
        // FIXME?: Is this the correct check to see if the user is logged in?
        if (!this.securityService.accessToken || this.idle.isRunning()) {
            return;
        }

        // sets an idle timeout of 10 minutes.
        this.idle.setIdle(600);
        // sets a timeout period of 5 minutes. After that, the user will be considered timed out.
        this.idle.setTimeout(300);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleStart.subscribe(() => {
            if (dialogRef) {
                return;
            }

            /*dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Ben je er nog?',
                    confirmButtonText: 'Verleng sessie',
                    cancelButtonText: 'Log uit',
                    showCloseButton: false,
                    idle: true
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    dialogRef = null;
                    this.reset();
                } else {
                    this.reset();
                    this.logout();
                }
            });*/
        });

        // sets the ping interval to 60 seconds
        // keepalive.interval(60);
        //
        // keepalive.onPing.subscribe(() => {
        //   console.log("Keep alive ping!");
        //   // TODO: do a call to backend to keep the session alive.
        //   this.lastPing = new Date();
        // });

        this.reset();
    }


    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    public logout() {
        this.securityService.logoff();
        this.timedOut = true;
        this.router.navigate(['/login']);
    }
}
