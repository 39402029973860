import {Component, inject, OnInit} from '@angular/core';
import {StepService} from "@core/services/step.service";
import {firstValueFrom} from "rxjs";
import {CompanyLocationService} from "@core/services/company-location.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss']
})
export class CreateAppointmentComponent implements OnInit {
  private stepList = inject(StepService).getSteps();
  get currentStep() {
    return this.stepList[this.stepService.currentStepIndex];
  }
  public locationId = null;

  constructor(
      private stepService: StepService,
      public locationService: CompanyLocationService,
      private route: ActivatedRoute,
      private gtmService: GoogleTagManagerService
  ) {
  }

  gtag(...args) {
    window['dataLayer'] = window['dataLayer'] || [];
    console.log('Pushing to dataLayer:', arguments);
    window['dataLayer'].push(arguments);
  }

  setConsent() {
    if (localStorage.getItem('consentMode') === null) {
      const consentMode = {
        'functionality_storage': 'denied',
        'security_storage': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'personalization_storage': 'denied',
      };

      try {
        this.gtag('consent', 'default', consentMode);
      } catch(e) {
        console.error('Error while setting default GTM consent mode', e);
      }
    } else {
      const gtmTag = JSON.parse(localStorage.getItem('consentMode'));

      try {
        this.gtag('consent', 'default', gtmTag);
      } catch(e) {
        console.error('Error while setting GTM consent mode', e);
      }
    }
  }

  async ngOnInit() {
    this.setConsent();
    // FIXME: this might/will cause an issue (i.e. GTM won't be loaded) if you navigate directly to a page in the appointment flow (like payment-complete) when we add external payment providers.
    //  Currently we don't have this flow.
    try {
      await this.gtmService.addGtmToDom();
    } catch (e) {
      console.error('Error while adding GTM to DOM', e);
    }

    const params = this.route.snapshot.queryParams;
    if (params['locationId']) {
      this.locationId = params['locationId'];
    } else {
      if (environment.appEnv === 'local') {
        this.locationId = '10000000-0000-0000-0000-000000000000';
      } else {
        console.log('No locationId found in query params');
        window.location.href = environment.websiteUrl + '/locaties';
      }
    }

    await this.getLocation();
    if (params['examType']) {
      this.stepService.selectedExamType = this.locationService.examinationTypes.find((e) => e.name === params['examType']);
    }
  }

  async getLocation() {
    this.locationService.activeLocation = (await firstValueFrom(this.locationService.get(this.locationId)));
  }
}
