<app-consent-banner></app-consent-banner>
<app-base-page [title]="currentStep.inputs['title']">
    <mat-spinner class="mx-auto" diameter="40" *ngIf="!locationService.activeLocation else steps"></mat-spinner>
    <ng-template #steps>
        <ng-container *ngComponentOutlet="
            currentStep.component;
            inputs: currentStep.inputs;
        "/>
    </ng-template>
</app-base-page>
