import {Injectable, Type} from "@angular/core";
import {
    SelectTreatmentComponent, StepComponent
} from "../../pages/create-appointment/steps/select-treatment/select-treatment.component";
import {Observable, Subject} from "rxjs";
import {
    SelectDateTimeComponent
} from "../../pages/create-appointment/steps/select-date-time/select-date-time.component";
import {
    EnterInformationComponent
} from "../../pages/create-appointment/steps/enter-information/enter-information.component";
import {PaymentComponent} from "../../pages/create-appointment/steps/payment/payment.component";
import {ConfirmationComponent} from "../../pages/create-appointment/steps/confirmation/confirmation.component";
import {HttpClient} from "@angular/common/http";
import {Appointment, CompleteAppointment, PublicAppointment} from "@util/types/models";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    public selectedDate = null;
    public selectedTime = null;
    public clientInformation: {
        initials: string,
        firstName: string,
        lastName: string,
        email: string,
        repeatEmail: string,
        phoneNumber: string,
        remarks: string,
        agree: boolean
    } = null;

    // fixme
    public appointmentId = null;
    public appointmentDTO: any = null;


    constructor(private http: HttpClient) {
    }

    create(dto: PublicAppointment): Observable<Appointment> {
        return this.http.post<Appointment>(
            // FIXME: UNCOMMENT WHEN CLIENT BACKEND IS LIVE!
            `${environment.clientBackendUrl}/public/appointment`,
            // `${environment.apiUrl}/public/appointment`,
            dto,
        );
    }

    complete(id: number, dto: CompleteAppointment): Observable<Appointment> {
        return this.http.post<Appointment>(
            // FIXME: UNCOMMENT WHEN CLIENT BACKEND IS LIVE!
            `${environment.clientBackendUrl}/public/appointment/${id}/complete`,
            // `${environment.apiUrl}/public/appointment/${id}/complete`,
            dto,
        );
    }

    sendCallbackEmail(body: {
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        remarks: string,
    }): Observable<Appointment> {
        return this.http.post<Appointment>(
            // FIXME: UNCOMMENT WHEN CLIENT BACKEND IS LIVE!
            `${environment.clientBackendUrl}/public/appointment/send-callback-email`,
            // `${environment.apiUrl}/public/appointment/${id}/complete`,
            body,
        );
    }
}
