import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Optional, SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {ControlContainer, FormArray, FormControl, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {FORM_ERRORS, PATTERN_ERRORS} from './form-errors';
import {FormErrorContainerDirective} from './form-error-container.directive';
import {FormErrorSubmitDirective} from './form-error-submit.directive';
import {debounceTime, distinctUntilChanged, EMPTY, merge, Observable, Subscription} from 'rxjs';
import {FormErrorComponent} from "@components/molecules/forms/form-error/form-error.component";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
  selector: '[formControlName], [formControl], [formArrayName], [popOverInput], [formGroupName]'
})
export class FormErrorHandlingDirective implements OnInit, OnDestroy {
  ref: ComponentRef<FormErrorComponent>;
  submit$: Observable<Event>;
  container: ViewContainerRef;

  @Input() serverError = {};
  @Input() showOnLabel = false;
  private labelClass = 'form__label'
  private labelElement;
  private formErrorContainer = null;

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() private formDir: NgControl,
    @Optional() private formGroupDirective: ControlContainer,
    private elementRef: ElementRef,
    @Optional() formErrorContainer: FormErrorContainerDirective,
    // so we removed the @Host() decorator here to support dynamic forms (Form element is outside of the formControl component's scope).
    // this might cause issues down the line, as I understand it will now just go up the hierachy until it finds a Form element.
    // read https://indepth.dev/posts/1063/a-curious-case-of-the-host-decorator-and-element-injectors-in-angular for a nice in depth view of @Host().
    // TODO: this might be a solution? https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
    @Optional() /*@Host()*/ private form: FormErrorSubmitDirective,
    @Inject(FORM_ERRORS) private errors,
    @Inject(PATTERN_ERRORS) private patternerrors,
    // private formErrorService: FormErrorService
  ) {
    setTimeout(() => {
      if (formErrorContainer) {
        this.formErrorContainer = formErrorContainer;
        this.container = this.formErrorContainer.vcr;

        // Check if formErrorContainer.elementRef.nativeElement is comment node
        if (formErrorContainer.elementRef?.nativeElement?.nodeName !== "#comment") {
          this.labelElement = formErrorContainer.elementRef?.nativeElement?.querySelector('.errorWrapper');
        }
      } else {
        this.container = vcr;
      }
      if (this.form) {
        this.submit$ = this.form.submit$;
      } else {
        this.submit$ = EMPTY;
      }
    }, 10) // FIXME: this shouldn't be needed. Seems to be caused by popovers.
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.setupValueChangeSubscription();
    setTimeout(() => {
      this.setupSubmitSubscription();
    }, 50);
  }

  private getControlErrors() {
    const directive = this.formDir ? this.formDir : this.formGroupDirective;
    const controlErrors = directive.errors;
    if (!controlErrors) return null;

    const firstKey = Object.keys(controlErrors)[0];
    return this.errors[firstKey];
  }

  private setupValueChangeSubscription(): void {
    const obs = this.formDir ? this.formDir.valueChanges : this.formGroupDirective.valueChanges;
    //untilDestroyed(this)
    obs
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((v) => {
        const getError = this.getControlErrors();
        if (!getError) {
          this.setError(null);
          return;
        }

        if (getError(this.formDir ? this.formDir.errors : this.formGroupDirective.errors).onChange) {
          this.setControlErrors();
        }
      });
  }

  private setupSubmitSubscription(): void {
    const getError = this.getControlErrors();
    if (!getError || !this.submit$) return;

    if (getError(this.formDir ? this.formDir.errors : this.formGroupDirective.errors).onSubmit) {
      // untilDestroyed(this)
      this.submit$
        .pipe()
        .subscribe((v) => {
          this.setControlErrors();
        });
    }
  }

  setControlErrors(): void {
    const controlErrors = this.formDir ? this.formDir.errors : this.formGroupDirective.errors;

    if (controlErrors) {
      const keys = Object.keys(controlErrors);
      const getErrors = keys.map(key => this.errors[key]).filter(x => x);
      if (getErrors.length <= 0) return;

      const texts = getErrors.map(getError => getError(controlErrors).text);
      this.setError(texts);
      this.ref.instance.errorStyling = true;
      this.changeFormUpdateStrategy(this.formDir?.control as FormControl, 'change');
    } else if (this.ref) {
      this.setError(null);
      this.changeFormUpdateStrategy(this.formDir?.control as FormControl, 'blur');
    }
  }

  setError(texts: string[]): void {
    let hasError = false;
    if (!this.ref) {
      if (this.elementRef?.nativeElement?.nodeName === "#comment") {
        return;
      }

      const factory = this.resolver.resolveComponentFactory(FormErrorComponent);
      const questionLabel = this.elementRef?.nativeElement?.closest('app-form-inputs')?.closest('.formPanel__formRow')?.querySelector('.question__wrap');
      const appendTo = questionLabel ? questionLabel : this.labelElement;
      hasError = appendTo?.querySelector('form-error');
      this.ref = this.container.createComponent(factory);
      if (!hasError) {
        appendTo?.appendChild(this.ref.location.nativeElement);
      }
    }

    if(texts?.length > 0) {
      if (!hasError) {
        this.ref.instance.texts = texts;
      }

      if (this.formErrorContainer) {
        this.formErrorContainer.hasError = true;
      }

      const formPanelRow = this.elementRef?.nativeElement?.closest('.formPanel__formRow');
      if (formPanelRow) {
        this.ref.instance.labelElement = formPanelRow;
        this.ref.instance.labelElementClass = `formPanel__formRow--error`;
      } else {
        const formInputElement = this.elementRef.nativeElement.closest('app-form-inputs');
        this.ref.instance.labelElement = formInputElement.querySelector(`.${this.labelClass}`);
        this.ref.instance.labelElementClass = `${this.labelClass}--error`;
      }
    } else {
      this.ref.instance.labelElementClass = null;
      this.ref.instance.errorStyling = null;
      this.ref.destroy();
      this.ref = null;

      if (this.formErrorContainer) {
        this.formErrorContainer.hasError = false;
      }
    }
  }

  changeFormUpdateStrategy(formControl: FormControl, strategy: 'blur' | 'change') {
    if(formControl) {
      Object.defineProperty(formControl, 'updateOn', {
        get: () => strategy,
        configurable: true
      });
    }
  }
}

// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const invalidCtrl = !!(control && control.invalid);
//     const invalidParent = !!(control && control.parent && control.parent.invalid);
//
//     return (invalidCtrl || invalidParent);
//   }
// }
